import React from "react";
import { graphql, Link, useStaticQuery, navigate } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

const AboutPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
						company
					}
				}
			}
		`
	);

	const handleGoBack = () => {
		navigate(-1);
	};

	const companyName = data.site.siteMetadata.company;
	const title = `Thank you for contacting us`;

	return (
		<Layout>
			<GatsbySeo title={title} language="en" noindex />
			<section>
				<Container>
					<Row className="py-5">
						<Col>
							<h1 className="pb-3">Thank you for contacting us</h1>
							<p>We will get back to you within as soon as possible.</p>
							<a style={{ cursor: "pointer" }} onClick={handleGoBack}>
								&#8592; Back
							</a>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};
export default AboutPage;
